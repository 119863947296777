import { Modal, Button, Tabs, Form, Input, message, Tooltip } from 'antd';
import React, { Component } from 'react';
import './add-folder-modal.less';
import { UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import getCookie from '../../utils/getCookie';
import { apiCreateGroup, apiJoinGroup, apiQuitGroup } from '../../services/API/API';
import { notification } from 'antd';

const { TabPane } = Tabs;

class GroupModal extends Component {
    state = {
        visible: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    setVisible = (visible) => {
        this.setState({
            visible: visible,
        });
    };

    handleOk = () => {
        this.Child.clickButton();
        setTimeout(() => {
            this.setVisible(false);
        }, 1000);
    };

    handleJoinGroup = (values) => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            ...values,
        };
        apiJoinGroup(params).then((res) => {
            if (res.data.code === 200) {
                message.success('加入成功');
                this.props.getGroupList();
                this.handleCancel();
            } else if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else if (res.data.code === 402) {
                message.error('你已在群内');
            } else if (res.data.code === 403) {
                message.error('该群不存在');
            } else {
                message.error('错误');
            }
        });
    };

    handleCreateGroup = (values) => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            ...values,
        };
        apiCreateGroup(params).then((res) => {
            if (res.data.code === 200) {
                message.success('建群成功');
                console.log(res.data);
                this.props.getGroupList();
                this.handleCancel();
            } else if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else if (res.data.code === 500) {
                message.error('新建失败');
            } else {
                message.error('错误');
            }
        });
    };

    handleQuitGroup = () => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            group_id: this.props.group_id,
        };
        apiQuitGroup(params).then((res) => {
            if (res.data.code === 200) {
                message.success('退出成功');
                this.props.getGroupList();
                this.handleCancel();
            } else if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else if (res.data.code === 403) {
                message.error('该群不存在');
            } else if (res.data.code === 421) {
                message.error('群主不可退群');
            } else {
                message.error('错误');
            }
        });
    };

    handleCancel = () => {
        this.setVisible(false);
    };

    render() {
        return (
            <div className={'group-modal'}>
                <Button type="primary" shape="circle" icon={<UsergroupAddOutlined />} onClick={this.showModal} />
                <Modal title="Join / Create Group" visible={this.state.visible} footer={null} onCancel={this.handleCancel}>
                    <div>
                        <Tabs defaultActiveKey="1" size={'large'} animated={true} centered={true} tabBarGutter={50}>
                            <TabPane tab="Join" key="1" style={{ padding: '20px 30px 10px' }}>
                                <Form onFinish={this.handleJoinGroup} initialValues={{ remember: true }}>
                                    <Form.Item name="group_id" rules={[{ required: true, message: 'Please input Group Id!' }]}>
                                        <Input prefix={<UsergroupAddOutlined id="item-icon" />} placeholder="Group Id" size="large" />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: '10px' }}>
                                        {
                                            <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
                                                Join
                                            </Button>
                                        }
                                    </Form.Item>
                                </Form>
                            </TabPane>
                            <TabPane tab="Create" key="2" style={{ padding: '20px 30px 0' }}>
                                <Form onFinish={this.handleCreateGroup} initialValues={{ remember: true }}>
                                    <Form.Item name="group_name" rules={[{ required: true, message: 'Please input Group Name!' }]}>
                                        <Input prefix={<UsergroupAddOutlined id="item-icon" />} placeholder="Group Name" size="large" />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: '10px' }}>
                                        <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
                                            Create
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </TabPane>
                            {this.props.group_id ? (
                                <TabPane tab="Quit" key="3" style={{ padding: '20px 30px 0' }}>
                                    <Form onFinish={this.handleQuitGroup} initialValues={{ remember: true }}>
                                        <Form.Item style={{ marginBottom: '10px' }}>
                                            <Button size="large" type="primary" htmlType="submit" style={{ width: '100%' }}>
                                                Quit Group
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            ) : null}
                        </Tabs>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default GroupModal;
