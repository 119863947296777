import React, {Component} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import DefaultLayout from "../layouts/DefaultLayout/DefaultLayout";
import HomePage from "../layouts/HomePage/HomePage";

class RouterWrap extends Component {
    render() {
        return (
            <div id={'router'}>
                <HashRouter>
                    <Switch>
                        <Route path={'/cloud'} component={DefaultLayout}/>
                        <Route path={'/'} component={HomePage} exact/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default RouterWrap;