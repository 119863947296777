import React, { Component } from 'react';
import { Layout, Breadcrumb, Button } from 'antd';
import './DefaultLayout.less';
import MySider from '../../components/Sider/MySider';
import PageRouter from '../../routes/PageRouter/PageRouter';

const { Content, Footer } = Layout;

class DefaultLayout extends Component {
    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <MySider />
                <Layout className="site-layout">
                    <Content style={{ margin: '0 16px' }}>
                        <PageRouter />
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>SCloud ©2022 Created by Shellmiao</Footer>
                </Layout>
            </Layout>
        );
    }
}

export default DefaultLayout;
