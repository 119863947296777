import Qs from 'qs';
import instance from '../../utils/Axios/UploadFile';
import EncryptObj from '../../utils/encrypt/encrypt';
import fetch from '../../utils/Axios/Axios';

// example 获取数据json模拟
export function apiRegister(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/register/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

// 登录校验
export function apiLogin(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/login/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiIsLogged() {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/profile/islogged/',
        method: 'get',
    });
}

export function apiLogout(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/logout/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiRootFolderId(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/folder/get_root_folder/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiFolderList(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/folder/folder_list/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiAddFolder(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/folder/add_folder/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiUploadFile(params) {
    console.log(params.get('username'));
    return instance({
        url: '/file/upload_file/',
        method: 'post',
        data: params,
    });
}

export function apiGetGroup(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/group/group_list/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiJoinGroup(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/group/join_group/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiCreateGroup(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/group/create_group/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiGroupRoot(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/group/get_group_root_folder/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiConfirm(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/check_token/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiCheck(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/send_email_verification_code/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiReset(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/account/reset_password/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiDownload(params) {
    let encryptobj = new EncryptObj();
    console.log('2333');
    console.log(encryptobj.cipherText);
    return encryptobj.postData({
        url: '/file/download_file/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiDelete(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/file/delete_file/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiDeleteFolder(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/folder/delete_folder/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiQuitGroup(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/group/quit_group/',
        method: 'post',
        data: Qs.stringify(params),
    });
}

export function apiUpload(params) {
    let encryptobj = new EncryptObj();
    return encryptobj.postData({
        url: '/file/upload_file/',
        method: 'post',
        data: Qs.stringify(params),
    });
}
