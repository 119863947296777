import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {LockOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import './UserForm.less'
import {apiRegister} from "../../services/API/API";

class RegisterForm extends Component {
    handleSubmitRegister = values => {
        console.log('Received values of form: ', values);
        let params = {
            username: values.username,
            email: values.email,
            password: values.password,
        }
        apiRegister(params).then(res => {
            if (res.data.code === 200) {
                message.success('注册成功');
                this.props.handleSelectKey()
            } else {
                message.error(res.data.error)
            }
        })
    };

    render() {
        return (
            <Form onFinish={this.handleSubmitRegister} className="register-form">
                <Form.Item
                    name="username"
                    rules={[{required: true, message: 'Please input your Username!'}]}
                >
                    <Input
                        prefix={<UserOutlined id="item-icon"/>}
                        placeholder="Username"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                    ]}
                >
                    <Input
                        prefix={<MailOutlined id="item-icon"/>}
                        placeholder="E-mail"
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="Password"
                                    prefix={<LockOutlined id="item-icon"/>}
                                    size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password prefix={<LockOutlined id="item-icon"/>}
                                    placeholder="Confirm Password"
                                    size="large"
                    />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit"
                            className="register-form-button" style={{width: '100%'}}>
                        Register
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}

export default RegisterForm;