import axios from 'axios';
import qs from 'qs';

// 实例对象
let instance = axios.create({
    baseURL: 'https://api.shellmiao.com/', // 这里是本地express启动的服务地址
    // timeout: 5000, // request timeout
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded'multipart/form-data
        'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
});

// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        // config.data = qs.stringify(config.data) // 转为formdata数据格式
        return config;
    },
    (error) => Promise.error(error)
);

export default instance;
