import React, {Component} from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import TeamPage from "../../components/Page/TeamPage";
import TestDataPage from "../../components/Page/TestDataPage";
import Folder from "../../components/Page/Folder";
import SettingPage from "../../components/Page/SettingPage";
import UserDisk from "../../components/Page/UserDisk";

class PageRouter extends Component {
    render() {
        return (
            <div id={'router'}>
                <HashRouter>
                    <Switch>
                        <Route path={'/cloud/file'} component={UserDisk}/>
                        <Route path={'/cloud/teams'} component={TeamPage}/>
                        <Route path={'/cloud/test'} component={TestDataPage}/>
                        <Route path={'/cloud/folder'} component={Folder}/>
                        <Route path={'/cloud/setting'} component={SettingPage}/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default PageRouter;