import axios from 'axios';
import qs from 'qs';
import '../encrypt/rsa';
import '../encrypt/aes';

let fetch = axios.create({
    baseURL: 'https://api.shellmiao.com/', // 这里是本地express启动的服务地址
    // timeout: 5000 // request timeout
});
// fetch.defaults.withCredentials = true
fetch.interceptors.request.use(
    (config) => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        // let regex = /.*csrftoken=([^;.]*).*$/
        // config.headers['X-CSRFToken'] = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1]
        if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
            if (typeof config.data !== 'string' && config.headers['Content-Type'] !== 'multipart/form-data') {
                config.data = qs.stringify(config.data);
            }
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

fetch.interceptors.response.use(
    async (data) => {
        return data;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 500) {
                console.log('服务器错误，请联系管理员处理');
            }
            console.log('1服务器错误，请联系管理员处理');
            return Promise.reject(error.response.data);
        } else {
            console.log('2服务器错误，请联系管理员处理');
            return Promise.reject(error);
        }
    }
);

export default fetch;
