import CryptoJS from 'crypto-js'

var iv = CryptoJS.enc.Utf8.parse("16-Bytes--String");   //加密向量
export function AESEnc(key, content) {
    var key = CryptoJS.enc.Utf8.parse(key);   //加密密钥
    var srcs = CryptoJS.enc.Utf8.parse(content);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {iv: iv, mode: CryptoJS.mode.CBC});
    return encrypted.toString();
}

export function AESDec(key, content) {
    var key = CryptoJS.enc.Utf8.parse(key);   //加密密钥
    var decrypted = CryptoJS.AES.decrypt(content, key, {iv: iv, mode: CryptoJS.mode.CBC});
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function getKey() {
    return uuid(16, 16);
}

export function uuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}


