export function dataURLtoFile(dataurl, filename) {
    console.log(dataurl);
    let arr = dataurl.split(','),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    let mime;
    if (arr[0].match(/:(.*?);/)) {
        mime = arr[0].match(/:(.*?);/)[1];
    } else {
        mime = arr[0];
    }
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
