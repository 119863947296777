import './rsa';
import './aes';
import { AESDec, AESEnc, getKey } from './aes';
import { RSA } from './rsa';
import Qs from 'qs';
import axios from 'axios';
import qs from 'qs';

class EncryptObj {
    key = '';

    encryptKey = '';

    cipherText = '';

    fetch = axios.create({
        baseURL: 'https://api.shellmiao.com/', // 这里是本地express启动的服务地址
        // timeout: 5000 // request timeout
    });

    encrypt = (content) => {
        console.log('2333333okokokok');
        this.key = getKey();
        this.encryptKey = RSA(this.key);
        this.cipherText = AESEnc(this.key, content);
        console.log(this.cipherText);
        this.fetch.interceptors.request.use(
            (config) => {
                config.headers['X-Requested-With'] = 'XMLHttpRequest';
                // let regex = /.*csrftoken=([^;.]*).*$/
                // config.headers['X-CSRFToken'] = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1]
                if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
                    if (typeof config.data !== 'string' && config.headers['Content-Type'] !== 'multipart/form-data') {
                        config.data = qs.stringify(config.data);
                    }
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        this.fetch.interceptors.response.use(
            async (data) => {
                console.log(data);
                console.log(data.data.enc_content);
                console.log(this.key);
                if (data.data.enc_content) {
                    console.log('明文: ' + AESDec(this.key, data.data.enc_content));
                    return JSON.parse(AESDec(this.key, data.data.enc_content));
                } else {
                    return data;
                }
            },
            (error) => {
                if (error.response) {
                    if (error.response.status === 500) {
                        console.log('服务器错误，请联系管理员处理');
                    }
                    console.log('1服务器错误，请联系管理员处理');
                    return Promise.reject(error.response.data);
                } else {
                    console.log('2服务器错误，请联系管理员处理');
                    return Promise.reject(error);
                }
            }
        );
    };

    postData = (param) => {
        this.encrypt(param.data);
        let e_param = {
            cipher_text: this.cipherText,
            enc_key: this.encryptKey,
        };
        console.log(this.key + '233ok');
        console.log(e_param);
        return this.fetch({
            url: param.url,
            method: param.method,
            data: Qs.stringify(e_param),
        });
    };
}

export default EncryptObj;
