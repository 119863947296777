import React, { Component } from 'react';
import { CloudDownloadOutlined, DeleteOutlined, FileTextTwoTone, FileTwoTone } from '@ant-design/icons';
import './File.less';
import { Button, Dropdown, Menu, message } from 'antd';
import { apiDelete, apiDownload, apiFolderList } from '../../services/API/API';
import getCookie from '../../utils/getCookie';
import { dataURLtoFile } from '../../utils/base64ToFile';

const fileDownload = require('js-file-download');

class File extends Component {
    state = {
        isOver: false,
    };
    onMouseOver = () => {
        this.setState({
            isOver: true,
        });
    };
    onMouseOut = () => {
        this.setState({
            isOver: false,
        });
    };

    handleDownload = () => {
        console.log('下载');
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            file_id: this.props.ele.file_id,
        };
        apiDownload(params).then((res) => {
            console.log('下载');
            if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else if (res.data.code === 402) {
                message.error('文件不存在');
                // window.location.href = "";
            } else if (res.data.code === 404) {
                message.error('没有下载文件的权限');
                // window.location.href = "";
            } else {
                message.success('下载开始');
                console.log('下载开始');
                console.log(res.data.file_b64);
                if (res.data.file_b64) {
                    let file_b = dataURLtoFile(res.data.file_b64, this.props.ele.file_name);
                    fileDownload(file_b, this.props.ele.file_name);
                } else {
                    let file_b = dataURLtoFile(res.data, this.props.ele.file_name);
                    fileDownload(file_b, this.props.ele.file_name);
                }

                // window.location.href = "";
            }
        });
    };

    handleDelete = () => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            file_id: this.props.ele.file_id,
        };
        apiDelete(params).then((res) => {
            if (res.data.code === 200) {
                message.success('删除成功');
                this.props.cdFolder(this.props.fatherFolderId);
            } else if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else if (res.data.code === 402) {
                message.error('文件不存在');
                // window.location.href = "";
            } else if (res.data.code === 404) {
                message.error('没有删除文件的权限');
                // window.location.href = "";
            } else {
                message.error('错误');
                // window.location.href = "";
            }
        });
    };

    render() {
        let { isOver } = this.state;
        const ele = this.props.ele;
        const menu = (
            <Menu>
                <Menu.Item key="download">
                    <Button onClick={this.handleDownload} type="dashed" icon={<CloudDownloadOutlined />}>
                        DOWNLOAD
                    </Button>
                </Menu.Item>
                <Menu.Item key="delete">
                    <Button onClick={this.handleDelete} type="dashed" icon={<DeleteOutlined />}>
                        &nbsp;&nbsp;&nbsp;&nbsp;DELETE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Button>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className={'file' + (isOver ? '-over' : '')} onMouseEnter={this.onMouseOver} onMouseLeave={this.onMouseOut}>
                {isOver ? (
                    <div className={'file-options'}>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow>
                            <a className="dropdown-link" onClick={(e) => e.preventDefault()}>
                                &nbsp;·&nbsp;·&nbsp;·&nbsp;
                            </a>
                        </Dropdown>
                    </div>
                ) : (
                    <div></div>
                )}
                {isOver ? <FileTextTwoTone className={'file-logo'} twoToneColor="#71B8FB" /> : <FileTwoTone className={'file-logo'} twoToneColor="#71B8FB" />}
                <div className={'file-name'}>{ele.file_name}</div>
                <div className={'file-date'}>{ele.update_time.substring(0, 10)}</div>
            </div>
        );
    }
}

export default File;
