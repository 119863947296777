import React, {Component} from "react";
import DiskPage from "./DiskPage";

class UserDisk extends Component {
    render() {
        return (
            <DiskPage type={'user'}/>
        )
    }
}

export default UserDisk;