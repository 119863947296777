import React, {Component} from "react";
import {DeleteOutlined, FolderOpenTwoTone, FolderTwoTone, PicCenterOutlined} from "@ant-design/icons";
import './Folder.less';
import {Button, Dropdown, Menu, message} from "antd";
import getCookie from "../../utils/getCookie";
import {apiDeleteFolder} from "../../services/API/API";

class Folder extends Component {
    state = {
        isOver: false
    }
    onMouseOver = () => {
        this.setState({
            isOver: true
        })
    }
    onMouseOut = () => {
        this.setState({
            isOver: false
        })
    }

    handleOpenFolder = () => {
        this.props.cdFolder(this.props.ele.folder_id, this.props.ele.folder_name)
    }

    handleDeleteFolder = () => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            folder_id: this.props.ele.folder_id,
        }
        apiDeleteFolder(params).then(res => {
            if (res.data.code === 200) {
                message.success('删除成功');
                this.props.cdFolder(this.props.fatherFolderId)
            } else if (res.data.code === 401) {
                message.error('未登录')
                window.location.href = "";
            } else if (res.data.code === 400) {
                message.error('文件夹不存在')
                // window.location.href = "";
            } else if (res.data.code === 404) {
                message.error('没有删除文件夹的权限')
                // window.location.href = "";
            } else {
                message.error('错误')
                // window.location.href = "";
            }
        })
    }

    render() {
        let {isOver} = this.state
        const ele = this.props.ele
        const menu = (
            <Menu>
                <Menu.Item key="open">
                    <Button onClick={this.handleOpenFolder} type="dashed" icon={<PicCenterOutlined/>}>
                        &nbsp;&nbsp;OPEN&nbsp;&nbsp;
                    </Button>
                </Menu.Item>
                <Menu.Item key="delete">
                    <Button onClick={this.handleDeleteFolder} type="dashed" icon={<DeleteOutlined/>}>
                        &nbsp;DELETE&nbsp;
                    </Button>
                </Menu.Item>
            </Menu>
        );
        return (
            <div className={'folder' + (isOver ? '-over' : '')} onMouseEnter={this.onMouseOver}
                 onMouseLeave={this.onMouseOut}
                 onDoubleClick={this.handleOpenFolder}
            >
                {
                    isOver ? <div className={'folder-options'}>
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft" arrow>
                            <a className="dropdown-link" onClick={e => e.preventDefault()}>
                                &nbsp;·&nbsp;·&nbsp;·&nbsp;
                            </a>
                        </Dropdown>
                    </div> : <div>

                    </div>
                }
                {
                    isOver ? <FolderOpenTwoTone className={'folder-logo'}/> :
                        <FolderTwoTone className={'folder-logo'}/>
                }
                <div className={'folder-name'}>
                    {ele.folder_name}
                </div>
            </div>
        )
    }
}

export default Folder;