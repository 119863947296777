import React, {Component} from "react";
import {Button, Form, message} from "antd";
import {apiLogout} from "../../services/API/API";
import getCookie from "../../utils/getCookie";
import deleteCookie from "../../utils/deleteCookie";
import {CheckCircleTwoTone, EnterOutlined, LoginOutlined, LogoutOutlined} from "@ant-design/icons";
import './UserForm.less'
import {Link} from "react-router-dom";

class LogoutForm extends Component {
    handleSubmitLogout = () => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
        }
        apiLogout(params).then(res => {
            if (res.data.code === 200) {
                message.success('登出成功');
                deleteCookie()
                this.props.handleLogged()
            } else if (res.data.code === 303) {
                message.error(res.data.error)
            }
        })
    };

    clickButton = () => {
        let e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true)
        document.getElementById('enter-link').dispatchEvent(e)
    }

    render() {
        return (
            <div className={'logged-div'}>
                <CheckCircleTwoTone className={'logged-logo'} twoToneColor="#52c41a"/>
                <Button className={'enter-button'} size="large" type="primary" icon={<LoginOutlined/>}
                        onClick={this.clickButton}>
                    <Link to={'/cloud/file'} style={{color: 'white'}} id={'enter-link'}>
                        &nbsp;&nbsp;&nbsp;Enter
                    </Link>
                </Button>
                <Form onFinish={this.handleSubmitLogout} className="login-form" initialValues={{remember: true}}>
                    <Form.Item style={{marginBottom: '10px'}}>
                        <Button size="large" type="primary" htmlType="submit" className="login-form-button"
                                style={{width: '100%'}} icon={<LogoutOutlined/>}>
                            Logout
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default LogoutForm;