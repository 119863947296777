import React, {Component} from "react";
import updateTheme from "../../utils/utils";
import {Button, Col, Row} from "antd";
import './SettingPage.less'
import {SketchPicker} from "react-color";
import UserPage from "../../layouts/User/UserPage";
import QueueAnim from "rc-queue-anim";

class SettingPage extends Component {
    state = {
        background: '#1890ff',
    };
    handleChangeComplete = (color) => {
        this.setState({background: color.hex});
        updateTheme(color.hex)
    };

    handleChangeCompleteHex = () => {
        this.setState({background: '#1890ff'});
        updateTheme('#1890ff')
    };

    render() {
        return (
            <div className="site-layout-background" style={{marginTop: 20, padding: 24, minHeight: '85vh'}}>
                <div className={'edit-div'}>
                    <div className={'edit-theme'}>
                        Custom Theme Color
                        <SketchPicker
                            color={this.state.background}
                            onChangeComplete={this.handleChangeComplete}
                            className={'pick-theme'}
                        />
                        <Button type="primary" className={'default-button'} onClick={this.handleChangeCompleteHex}>
                            Default
                        </Button>
                    </div>
                </div>
                <div className={'UserPage'}>
                    <QueueAnim forcedReplay={false}>
                        <div key="UserPage">
                            <UserPage comeFrom={'setting'}/>
                        </div>
                    </QueueAnim>
                </div>
            </div>
        )
    }
}

export default SettingPage;