import {Modal, Button} from 'antd';
import React, {Component} from "react";
import UploadFile from "./UploadFile";
import './UploadFileModal.less'
import {UploadOutlined} from "@ant-design/icons";

class UploadFileModal extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        actionName: this.props.actionName,
    }

    showModal = () => {
        this.setState({
            visible: true
        })
    }

    setVisible = visible => {
        this.setState({
            visible: visible
        })
    }

    setConfirmLoading = ConfirmLoading => {
        this.setState({
            confirmLoading: ConfirmLoading
        })
    }

    handleOk = () => {
        this.Child.clickButton()
        this.setConfirmLoading(true);
        setTimeout(() => {
            this.setVisible(false);
            this.setConfirmLoading(false);
        }, 1000);
    };

    handleCancel = () => {
        this.setVisible(false);
    };

    render() {
        return (
            <div className={'upload-file-modal'}>
                <Button type="primary" onClick={this.showModal} icon={<UploadOutlined/>}>
                    UPLOAD
                </Button>
                <Modal
                    title={this.state.actionName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <UploadFile onRef={c => this.Child = c} cdFolder={this.props.cdFolder}
                                    fatherFolderId={this.props.fatherFolderId} setVisible={this.setVisible.bind(this)}/>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default UploadFileModal;