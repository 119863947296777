import React, { Component } from 'react';
import { Layout } from 'antd';
import { CloudServerOutlined } from '@ant-design/icons';
import './HomePage.less';
import UserPage from '../User/UserPage';
import QueueAnim from 'rc-queue-anim';

const { Header, Footer, Content } = Layout;

class HomePage extends Component {
    render() {
        return (
            <Layout>
                <Header id={'HomeHeader'}>
                    <CloudServerOutlined />
                    &nbsp;SCloud
                </Header>
                <Content className={'HomeContent'}>
                    <QueueAnim forcedReplay={false}>
                        <div key="UserPage">
                            <UserPage comeFrom={'home'} />
                        </div>
                    </QueueAnim>
                </Content>
                <Footer style={{ textAlign: 'center' }}>SCloud ©2022 Created by Shellmiao</Footer>
            </Layout>
        );
    }
}

export default HomePage;
