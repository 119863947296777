function deleteCookie() {
    let c_name = 'username'
    let date = new Date();
    date.setTime(date.getTime() - 1);
    let c_start;
    let c_end;
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(c_name + '=')
        if (c_start !== -1) {
            c_end = document.cookie.length
            document.cookie = unescape(document.cookie.substring(c_start, c_end)) + ';expire=' + date.toUTCString();
        }
    }
}

export default deleteCookie;