import {Modal, Button} from 'antd';
import React, {Component} from "react";
import AddFolderForm from "./AddFolder";
import './add-folder-modal.less'
import {FolderAddOutlined} from "@ant-design/icons";

class AddFolderModal extends Component {
    state = {
        visible: false,
        confirmLoading: false,
        actionName: this.props.actionName,
    }

    showModal = () => {
        this.setState({
            visible: true
        })
    }

    setVisible = visible => {
        this.setState({
            visible: visible
        })
    }

    setConfirmLoading = ConfirmLoading => {
        this.setState({
            confirmLoading: ConfirmLoading
        })
    }

    handleOk = () => {
        this.Child.clickButton()
        this.setConfirmLoading(true);
        setTimeout(() => {
            this.setVisible(false);
            this.setConfirmLoading(false);
        }, 1000);
    };

    handleCancel = () => {
        this.setVisible(false);
    };

    render() {
        return (
            <div className={'add-folder-modal'}>
                <Button type="primary" onClick={this.showModal} icon={<FolderAddOutlined/>}>
                    ADD
                </Button>
                <Modal
                    title={this.state.actionName}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <div>
                        <AddFolderForm onRef={c => this.Child = c} fatherFolderId={this.props.fatherFolderId}
                                       cdFolder={this.props.cdFolder}/>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default AddFolderModal;