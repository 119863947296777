import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { CloudServerOutlined, FolderOutlined, SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './MySider.less';
import QueueAnim from 'rc-queue-anim';

const { Sider } = Layout;
const { SubMenu } = Menu;

class MySider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            isLogged: false,
        };
    }

    onCollapse = (collapsed) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    handleClickLogo = () => {
        window.location.href = '';
    };

    render() {
        return (
            <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                <div className={this.state.collapsed ? 'st-logo-div-collapsed' : 'st-logo-div'} onClick={this.handleClickLogo}>
                    <div className={this.state.collapsed ? 'st-logo-collapsed' : 'st-logo'}>
                        <a style={{ color: 'white' }}>
                            <CloudServerOutlined />
                        </a>
                        {this.state.collapsed ? null : (
                            <div className={'st-title'}>
                                <QueueAnim>
                                    <div className={'st-title'} key={'st-title'}>
                                        <a style={{ color: 'white' }}>&nbsp;SCloud</a>
                                    </div>
                                </QueueAnim>
                            </div>
                        )}
                    </div>
                </div>
                <Menu theme="dark" defaultSelectedKeys={['file']} mode="inline" className={'sider-menu'}>
                    <Menu.Item key="account" icon={<UserOutlined />} disabled={true}>
                        <Link to={'/cloud/account'}>Account</Link>
                    </Menu.Item>
                    <Menu.Item key="file" icon={<FolderOutlined />}>
                        <Link to={'/cloud/file'}>My Files</Link>
                    </Menu.Item>
                    <Menu.Item key="team" icon={<TeamOutlined />}>
                        <Link to={'/cloud/teams'}>My Team</Link>
                    </Menu.Item>

                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item disabled={true}></Menu.Item>
                    <Menu.Item key="setting" icon={<SettingOutlined />}>
                        <Link to={'/cloud/setting'}>Setting</Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }
}

export default MySider;
