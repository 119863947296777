import React, {Component} from "react";
import LoginForm from "../../components/Forms/LoginForm";
import {Col, message, Tabs} from "antd";
import RegisterForm from "../../components/Forms/RegisterForm";
import './UserPage.less'
import ForgetPasswordForm from "../../components/Forms/ForgetPasswordForm";
import LogoutForm from "../../components/Forms/LogoutForm";
import getCookie from "../../utils/getCookie";
import {apiRootFolderId} from "../../services/API/API";

const {TabPane} = Tabs;

class UserPage extends Component {
    state = {
        loginStatus: "login",
        tagWidth: this.props.comeFrom === 'home' ? 8 : 20,
        tagOffset: this.props.comeFrom === 'home' ? 12 : 2,
        selectedKey: '1',
    };

    componentWillMount() {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
        }
        apiRootFolderId(params).then(res => {
            if (res.data.code === 200) {
                this.setState({
                    loginStatus: 'logged',
                })
            } else if (res.data.code === 401) {
                message.error('未登录')
            } else {
                message.error('错误')
            }
        })
    }

    handleLogged = () => {
        this.setState({
            loginStatus: this.state.loginStatus === 'login' ? 'logged' : 'login',
        })
    }

    handleSelectKey = () => {
        this.setState({
            selectedKey: this.state.selectedKey === '1' ? '2' : '1',
        })
        this.handleLoginStatus('login')
    }

    handleLoginStatus = LoginStatus => {
        this.setState(
            {
                loginStatus: LoginStatus,
            }
        )
        if (LoginStatus === 'forget') {
            this.setState(
                {
                    tagWidth: this.props.comeFrom === 'home' ? 10 : 24,
                    tagOffset: this.props.comeFrom === 'home' ? 11 : 0,
                }
            )
        } else {
            this.setState(
                {
                    tagWidth: this.props.comeFrom === 'home' ? 8 : 20,
                    tagOffset: this.props.comeFrom === 'home' ? 12 : 2,
                }
            )
        }
    }

    render() {
        let {loginStatus, tagWidth, tagOffset} = this.state;
        const renderTabBar = (props, DefaultTabBar) => (

            <DefaultTabBar {...props} style={{width: '100%'}}/>

        );
        return (
            <Col span={tagWidth} offset={tagOffset} className={'UserCard'}>
                <Tabs
                    onChange={this.handleSelectKey}
                    size={'large'}
                    animated={true}
                    centered={true}
                    tabBarGutter={50}
                    renderTabBar={renderTabBar}
                    activeKey={this.state.selectedKey}
                >
                    <TabPane
                        tab={loginStatus === 'forget' ? "RESET PASSWORD" : loginStatus === 'login' ? "LOGIN" : 'LOGOUT'}
                        key="1"
                        style={{padding: "20px 30px 10px"}}>
                        {
                            loginStatus === 'login' ?
                                <LoginForm handleLoginStatus={this.handleLoginStatus.bind(this)}
                                           handleLogged={this.handleLogged.bind(this)}/>
                                : loginStatus === 'forget' ?
                                    <ForgetPasswordForm handleLoginStatus={this.handleLoginStatus.bind(this)}/>
                                    : <LogoutForm handleLogged={this.handleLogged.bind(this)}/>
                        }
                    </TabPane>
                    {
                        loginStatus === 'logged' ? null :
                            <TabPane tab="REGISTER" key="2" style={{padding: "20px 30px 0"}}>
                                <RegisterForm handleSelectKey={this.handleSelectKey.bind(this)}/>
                            </TabPane>
                    }
                </Tabs>
            </Col>
        );
    }
}

export default UserPage;