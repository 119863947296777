import React, {Component} from "react";
import {Button, Form, Input, message, Tabs, Upload} from "antd";
import getCookie from "../../utils/getCookie";
import {InboxOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {apiUpload, apiUploadFile} from "../../services/API/API";
import {file} from "ant-design-icons";
import ReactFileReader from "react-file-reader";
import './UploadFile.less'

const {Dragger} = Upload;

const {TabPane} = Tabs;

class UploadFile extends Component {
    state = {
        fileList: [],
        files: '',
        file_name: '',
        isEn: false
    }

    constructor(props) {
        super(props);
        if (props.onRef) {
            props.onRef(this)
        }
    }

    clickButton = () => {
        if (!this.state.isEn) {
            let e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true)
            document.getElementById('upload-file-form-button').dispatchEvent(e)
        } else {

        }
    }

    setIsEn = () => {
        this.setState({
            isEn: !this.state.isEn
        })
    }

    handleUploadFile = () => {
        const {fileList} = this.state
        var formData = new FormData();
        fileList.forEach(file => {
            formData.append('file', file);
            console.log('大小为：' + file.size)
        })
        formData.append('username', getCookie('username'))
        formData.append('token', getCookie('token'))
        formData.append('father_folder_id', this.props.fatherFolderId)
        apiUploadFile(formData).then(res => {
            if (res.data.code === 200) {
                message.success('添加成功');
                this.props.cdFolder(this.props.fatherFolderId)
            } else {
                message.error(res.data.error)
            }
        })
    };

    handleUploadEnFile = () => {
        let fatherFolderId = this.props.fatherFolderId;
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            father_folder_id: fatherFolderId,
            file_b64: this.state.files,
            file_name: this.state.file_name
        }
        console.log(params)
        apiUpload(params).then(res => {
            if (res.data.code === 200) {
                message.success('上传成功');
                this.props.cdFolder(this.props.fatherFolderId)
            } else {
                message.error(res.data.error)
            }
        })
    };

    handleFiles = (files) => {
        this.setState({
            files: files.base64,
            file_name: files.fileList[0].name
        })
        this.handleUploadEnFile()
        this.clickButton()
    }

    beforeUpload = file => {
        this.setState({
            fileList: [...this.state.fileList, file]
        })
        return false
    }

    render() {
        return (
            <div className={'UploadFile-div'}>
                <Tabs
                    defaultActiveKey="1"
                    size={'large'}
                    animated={true}
                    centered={true}
                    tabBarGutter={50}
                    onChange={this.setIsEn}
                >
                    <TabPane
                        tab='Not Encrypted'
                        key="1"
                        style={{padding: "20px 30px 10px"}}>
                        <Form onFinish={this.handleUploadFile} initialValues={{remember: true}}>
                            <Form.Item
                                name="upload"
                                rules={[{required: true, message: 'Please upload file!'}]}
                            >
                                <Dragger
                                    beforeUpload={this.beforeUpload}
                                    name={'upload'}
                                    multiple={false}
                                    maxCount={1}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company
                                        data or
                                        other
                                        band files
                                    </p>
                                </Dragger>
                            </Form.Item>
                            <Form.Item style={{height: "1px"}}>
                                <Button size="large" type="primary" htmlType="submit" id="upload-file-form-button"
                                        style={{
                                            width: '100%',
                                            display: 'none',
                                            float: 'bottom',
                                            marginBottom: '100vh'
                                        }}>
                                    Upload
                                </Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="Encrypt" key="2" style={{padding: "20px 30px 0"}}>
                        <ReactFileReader
                            base64={true}
                            multipleFiles={false}
                            handleFiles={this.handleFiles}
                        >
                            <Button size="large" type="primary" htmlType="submit"
                                    style={{width: '100%',}}>
                                Select File
                            </Button>
                        </ReactFileReader>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default UploadFile;