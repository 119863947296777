import React, { Component } from 'react';
import DiskPage from './DiskPage';
import { message, Tabs } from 'antd';
import getCookie from '../../utils/getCookie';
import { apiGetGroup } from '../../services/API/API';
import './TeamPage.less';
import GroupModal from './GroupModal';
import { TeamOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class TeamPage extends Component {
    state = {
        currentId: -1,
        size: 8,
        GroupList: [],
        data: [],
    };

    componentWillMount() {
        this.getGroupList();
    }

    getGroupList = () => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
        };
        apiGetGroup(params).then((res) => {
            if (res.data.code === 200) {
                console.log('成功');
                this.setState({
                    GroupList: res.data.group_list,
                });
            } else if (res.data.code === 401) {
                message.error('未登录');
                window.location.href = '';
            } else {
                message.error('错误');
            }
        });
    };

    render() {
        const { GroupList } = this.state;
        return (
            <div className={'TeamDiv'}>
                <div className={'TeamsTags'}>
                    <Tabs tabPosition="right" centered={true}>
                        <TabPane tab={'User'} key={'User'}>
                            <div className={'TeamPage'}>
                                <DiskPage type={'user'} getGroupList={this.getGroupList.bind(this)} />
                            </div>
                        </TabPane>
                        {GroupList.map((ele, index) => (
                            <TabPane tab={ele.group_name + '[' + ele.group_id + ']'} key={ele.group_id} closeIcon={<TeamOutlined />}>
                                <div className={'TeamPage'}>
                                    <DiskPage group_id={ele.group_id} group_name={ele.group_name} getGroupList={this.getGroupList.bind(this)} type={'team'} />
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default TeamPage;
