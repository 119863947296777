import client from "webpack-theme-color-replacer/client";
import {generate} from "@ant-design/colors";

// 同样的 antd-react和antd-vue获取generate方法的路径是不同的

function getAntdSerials(color) {
    // 淡化（即less的tint）
    const lightens = new Array(9).fill().map((t, i) => {
        return client.varyColor.lighten(color, i / 10);
    });
    // colorPalette变换得到颜色值
    const colorPalettes = generate(color);
    const rgb = client.varyColor.toNum3(color.replace("#", "")).join(",");
    return lightens.concat(colorPalettes).concat(rgb);
}

function changeColor(newColor) {
    var options = {
        newColors: getAntdSerials(newColor), // new colors array, one-to-one corresponde with `matchColors`
        changeUrl(cssUrl) {
            return `/${cssUrl}`; // while router is not `hash` mode, it needs absolute path
        },
    };
    return client.changer.changeColor(options, Promise);
}

function updateTheme(newPrimaryColor) {
    const hideMessage = () => console.log("正在切换主题！", 0);
    changeColor(newPrimaryColor).finally((t) => {
        setTimeout(() => {
            hideMessage();
        });
    });
}


export default updateTheme;