import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {apiAddFolder} from "../../services/API/API";
import getCookie from "../../utils/getCookie";
import {FolderOutlined, UserOutlined} from "@ant-design/icons";

class AddFolderForm extends Component {
    constructor(props) {
        super(props);
        if (props.onRef) {
            props.onRef(this)
        }
    }

    clickButton = () => {
        let e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true)
        document.getElementById('add-folder-form-button').dispatchEvent(e)
    }

    handleAddFolder = values => {
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            father_folder_id: this.props.fatherFolderId,
            ...values
        }
        console.log(params)
        apiAddFolder(params).then(res => {
            if (res.data.code === 200) {
                message.success('添加成功');
                this.props.cdFolder(this.props.fatherFolderId)
            } else if (res.data.code === 401) {
                message.error('未登录')
                window.location.href = "";
            } else {
                message.error('错误')
                window.location.href = "";
            }
        })
    };

    render() {
        return (
            <div className={'add-folder-div'} style={{height: '5vh'}}>
                <Form onFinish={this.handleAddFolder} className="login-form" initialValues={{remember: true}}>
                    <Form.Item
                        name="folder_name"
                        rules={[{required: true, message: 'Please input Folder Name!'}]}
                    >
                        <Input
                            prefix={<FolderOutlined id="item-icon"/>}
                            placeholder="Folder Name"
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item style={{marginBottom: '10px'}}>
                        <Button size="large" type="primary" htmlType="submit" id="add-folder-form-button"
                                style={{width: '100%', display: 'none', float: 'bottom', marginBottom: '100vh'}}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default AddFolderForm;