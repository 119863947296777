import React, { Component } from 'react';
import { Space, Slider, message, Breadcrumb, Tooltip } from 'antd';
import Folder from './Folder';
import File from './File';
import { apiFolderList, apiGroupRoot, apiRootFolderId } from '../../services/API/API';
import getCookie from '../../utils/getCookie';
import './DiskPage.less';
import AddFolderModal from './AddFolderModal';
import UploadFileModal from './UploadFileModal';
import GroupModal from './GroupModal';
import { CopyTwoTone } from '@ant-design/icons';
import encrypt from '../../utils/encrypt/encrypt';
import QueueAnim from 'rc-queue-anim';

class DiskPage extends Component {
    state = {
        type: this.props.type,
        currentId: -1,
        size: 8,
        addressList: [],
        data: [],
        haveFolder: false,
        haveFile: false,
    };

    getRootFolderIdAndInter = () => {
        console.log(document.cookie);
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
        };
        console.log('获得参数:' + params.username + params.token);
        if (this.props.type === 'team') {
            params = {
                group_id: this.props.group_id,
                ...params,
            };
            apiGroupRoot(params).then((res) => {
                if (res.data.code === 200) {
                    console.log('成功');
                    this.setState({
                        currentId: res.data.root_folder_id,
                    });
                    this.cdFolder(this.state.currentId, this.props.group_name);
                } else if (res.data.code === 401) {
                    message.error('未登录');
                } else if (res.data.code === 403) {
                    message.error('群不存在');
                    window.location.href = '';
                } else {
                    message.error('错误');
                    window.location.href = '';
                }
            });
        } else {
            apiRootFolderId(params).then((res) => {
                if (res.data.code === 200) {
                    console.log('成功');
                    this.setState({
                        currentId: res.data.root_folder_id,
                    });
                    this.cdFolder(this.state.currentId, getCookie('username'));
                } else if (res.data.code === 401) {
                    message.error('未登录');
                    // window.location.href = "";
                } else {
                    message.error('错误');
                    window.location.href = '';
                }
            });
        }
    };

    cdFolder = (FolderId, FolderName) => {
        console.log('正在进入' + FolderId);
        let params = {
            username: getCookie('username'),
            token: getCookie('token'),
            folder_id: FolderId,
        };
        apiFolderList(params).then((res) => {
            let addressList = this.state.addressList;
            let temp = -1;
            for (let i = 0; i < addressList.length; i++) {
                if (addressList[i].id === FolderId) {
                    temp = i;
                    break;
                }
            }
            if (temp === -1) {
                addressList.push({
                    name: FolderName,
                    id: FolderId,
                });
            } else {
                addressList.splice(temp + 1, addressList.length - temp - 1);
            }
            if (res.data.code === 200) {
                this.setState({
                    currentId: FolderId,
                    data: res.data.children,
                    // addressList: [...this.state.addressList, {
                    //     name: FolderName,
                    //     id: FolderId
                    // }]
                    addressList: addressList,
                });
                let haveFileTemp = false;
                let haveFolderTemp = false;
                res.data.children.forEach((ele) => {
                    if (ele.type === 'file') {
                        haveFileTemp = true;
                    }
                    if (ele.type === 'folder') {
                        haveFolderTemp = true;
                    }
                });
                this.setState({
                    haveFile: haveFileTemp,
                    haveFolder: haveFolderTemp,
                });
            } else if (res.data.code === 401) {
                message.error('未登录');
                // window.location.href = "";
            } else {
                message.error('错误');
                // window.location.href = "";
            }
        });
    };

    componentWillMount() {
        this.getRootFolderIdAndInter();
    }

    componentDidMount() {}

    setSize = (size) => {
        this.setState({
            size: size,
        });
    };

    renderBread(addressList) {
        return addressList.map((ele, index) => {
            return this.props.type === 'team' ? (
                <Tooltip title={'Current Group ID : ' + this.props.group_id}>
                    <Breadcrumb.Item key={ele.name} className="breadcrumb-line" onClick={(ev) => this.cdFolder(ele.id, ele.name)}>
                        <a>{ele.name}</a>
                    </Breadcrumb.Item>
                </Tooltip>
            ) : (
                <Breadcrumb.Item key={ele.name} className="breadcrumb-line" onClick={(ev) => this.cdFolder(ele.id, ele.name)}>
                    <a>{ele.name}</a>
                </Breadcrumb.Item>
            );
        });
    }

    getGroupList = () => {
        if (this.props.getGroupList) {
            this.props.getGroupList();
        }
    };

    render() {
        return (
            <div>
                <div className={'disk-header'}>
                    <Breadcrumb.Item key={'root'} className="breadcrumb-line"></Breadcrumb.Item>
                    {this.renderBread(this.state.addressList)}
                    {
                        <div className={'group-modal'}>
                            <GroupModal group_id={this.props.group_id} getGroupList={this.getGroupList.bind(this)} />
                        </div>
                    }
                </div>
                <div className="site-layout-background" style={{ marginTop: 10, padding: 24, minHeight: '82vh' }}>
                    <div>
                        <Slider value={this.state.size} onChange={(value) => this.setSize(value)} className={'disk-slider'} />
                        <AddFolderModal actionName={'Add Folder'} fatherFolderId={this.state.currentId} cdFolder={this.cdFolder.bind(this)} className={'add-folder-button'} />
                        <UploadFileModal actionName={'Upload File'} fatherFolderId={this.state.currentId} cdFolder={this.cdFolder.bind(this)} className={'upload-file-button'} />
                    </div>
                    <div className={'folder-page'}>
                        <Space className={'disk-content'} size={this.state.size} wrap>
                            {this.state.data.length === 0 ? (
                                <div className={'no-files-div'}>
                                    <CopyTwoTone />
                                    &nbsp;&nbsp;NO&nbsp;&nbsp;FILES
                                </div>
                            ) : this.state.haveFolder ? (
                                this.renderFolderList(this.state.data)
                            ) : null}
                            {this.state.data.length === 0 ? null : this.state.haveFile ? this.renderFileList(this.state.data) : null}
                        </Space>
                    </div>
                </div>
            </div>
        );
    }

    renderFolderList(dataList) {
        return dataList.map((ele, index) => {
            return ele.type === 'folder' ? (
                <div key={ele.folder_id + ele.folder_name + 'folder'}>
                    <Folder ele={ele} cdFolder={this.cdFolder.bind(this)} fatherFolderId={this.state.currentId} />
                </div>
            ) : null;
        });
    }

    renderFileList(dataList) {
        return dataList.map((ele, index) => {
            return ele.type === 'folder' ? null : (
                <div key={ele.file_id + ele.file_name + 'file'}>
                    <File ele={ele} fatherFolderId={this.state.currentId} cdFolder={this.cdFolder.bind(this)} />
                </div>
            );
        });
    }
}

export default DiskPage;
